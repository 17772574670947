/* Christmas theme colors and decorations */
.main-wrapper {
  background: linear-gradient(to bottom, #1a472a, #2d5a40);
  position: relative;
  overflow: hidden;
}

/* Improved Snow Effect */
.snow-enabled .main-wrapper::before,
.snow-enabled .main-wrapper::after {
  content: '';
  position: fixed;
  top: -100%;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: 
    radial-gradient(4px 4px at 100px 50px, #fff, transparent),
    radial-gradient(6px 6px at 200px 150px, #fff, transparent),
    radial-gradient(3px 3px at 300px 250px, #fff, transparent),
    radial-gradient(4px 4px at 400px 350px, #fff, transparent),
    radial-gradient(6px 6px at 500px 100px, #fff, transparent),
    radial-gradient(3px 3px at 50px 200px, #fff, transparent),
    radial-gradient(4px 4px at 150px 300px, #fff, transparent),
    radial-gradient(6px 6px at 250px 400px, #fff, transparent),
    radial-gradient(3px 3px at 350px 500px, #fff, transparent);
  background-size: 650px 650px;
  animation: snow 8s linear infinite;
  opacity: 0.5;
  pointer-events: none;
}

.snow-enabled .main-wrapper::after {
  background-image: 
    radial-gradient(6px 6px at 150px 150px, #fff, transparent),
    radial-gradient(3px 3px at 250px 250px, #fff, transparent),
    radial-gradient(4px 4px at 350px 350px, #fff, transparent),
    radial-gradient(6px 6px at 450px 450px, #fff, transparent),
    radial-gradient(3px 3px at 550px 550px, #fff, transparent),
    radial-gradient(4px 4px at 100px 300px, #fff, transparent),
    radial-gradient(6px 6px at 200px 400px, #fff, transparent),
    radial-gradient(3px 3px at 300px 500px, #fff, transparent);
  background-size: 600px 600px;
  animation: snow 10s linear infinite;
}

@keyframes snow {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(100%);
  }
}

/* Christmas decorations */
.main-logo {
  position: relative;
}

.main-logo::before,
.main-logo::after {
  content: '🎄';
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
}

.main-logo::before {
  left: -40px;
}

.main-logo::after {
  right: -40px;
}

/* Festive buttons */
button {
  background: #c41e3a !important; /* Christmas red */
  border: 2px solid #fff !important;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.3) !important;
  transition: all 0.3s ease !important;
  color: white !important;
}

button:hover {
  background: #008000 !important; /* Christmas green */
  transform: scale(1.05) !important;
}

/* Text colors */
h1, h2, h3, h4, h5, h6 {
  color: #fff !important;
  text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

/* Add holly decoration to corners */
.main-content {
  position: relative;
}

.main-content::before,
.main-content::after {
  content: '🎅';
  position: fixed;
  font-size: 2.5rem;
}

.main-content::before {
  top: 20px;
  right: 20px;
}

.main-content::after {
  bottom: 20px;
  left: 20px;
}

/* Add some twinkling stars */
.twinkle {
  position: fixed;
  width: 4px;
  height: 4px;
  background: white;
  border-radius: 50%;
  animation: twinkle 1s infinite;
}

@keyframes twinkle {
  0%, 100% { opacity: 1; }
  50% { opacity: 0.3; }
}

/* Add some responsiveness */
@media (max-width: 768px) {
  .main-logo::before,
  .main-logo::after {
    font-size: 1.5rem;
  }

  .main-content::before,
  .main-content::after {
    font-size: 2rem;
  }
} 