@font-face {
  font-family: "Brandon Grotesque";
  src: url("../font/BrandonGrotesque-LightItalic.woff2") format("woff2"),
    url("../font/BrandonGrotesque-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Brandon Grotesque";
  src: url("../font/BrandonGrotesque-Light.woff2") format("woff2"),
    url("../font/BrandonGrotesque-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Brandon Grotesque";
  src: url("../font/BrandonGrotesque-Black.woff2") format("woff2"),
    url("../font/BrandonGrotesque-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Brandon Grotesque";
  src: url("../font/BrandonGrotesque-BoldItalic.woff2") format("woff2"),
    url("../font/BrandonGrotesque-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Brandon Grotesque";
  src: url("../font/BrandonGrotesque-MediumItalic.woff2") format("woff2"),
    url("../font/BrandonGrotesque-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Brandon Grotesque";
  src: url("../font/BrandonGrotesque-ThinItalic.woff2") format("woff2"),
    url("../font/BrandonGrotesque-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Brandon Grotesque";
  src: url("../font/BrandonGrotesque-RegularItalic.woff2") format("woff2"),
    url("../font/BrandonGrotesque-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

/* Css reset  */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  font-family: "Brandon Grotesque", sans-serif !important;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
  background-color: #231f20;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.main-wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  color: white;
  justify-content: center;
  align-items: center;
  background: #231f20;
}

.about-text {
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 15px;
  font-family: Brandon Grotesque;
  text-align: center;
}

.main-logo {
  text-align: center;
  margin-bottom: 10px;
}

.student-details {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #231f20;
  color: white;
}

.social-img {
  margin-left: 130px;
}

.text-end {
  display: flex;
  justify-content: end;
  font-weight: bold;
}

.text-end p {
  width: 100%;
}

.user-name {
  font-family: Brandon Grotesque;
  font-weight: bold;
  font-size: 20.34px;
  cursor: pointer;
  color: #a3a3a3;
  margin-left: 5px;
}

.selected-username {
  font-family: Brandon Grotesque;
  font-weight: bold;
  font-size: 20.34px;
  color: #000000;
  margin-left: 5px;
  cursor: pointer;
}

.user {
  color: #a3a3a3;
}

.multi-user {
  color: red;
}

.all-users {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  background-color: #231f20;
  color: white !important;
}

.student-details img {
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.main-div {
  display: flex;
  width: 500px !important;
  font-family: Brandon Grotesque;
  font-weight: bold;
  font-size: 20.34px;
  justify-content: space-between !important;
  background-color: white;
  color: #a3a3a3;
  margin-top: 10px;
  cursor: pointer;
  padding: 5px;
  border-radius: 10px;
}

.selected-main-div {
  display: flex;
  width: 500px !important;
  cursor: pointer;
  justify-content: space-between !important;
  background-color: white;
  color: #000000;
  margin-top: 10px;
  padding: 5px;
  border-radius: 10px;
}

.main-div .usersingle {
  color: #a3a3a3;
}

.main-div .tick-mark {
  background-color: #a3a3a3;
  color: white;
  padding: 8px;
  border-radius: 20px;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
}

.selected-main-div .tick-mark {
  background-color: #ffaf00;
  color: white;
  padding: 8px;
  border-radius: 20px;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
}

.selected-main-div .usersingle {
  color: #000000;
}

.allchecked h1 {
  text-align: center;
  font-family: Brandon Grotesque;
  font-size: 71.93px;
  font-weight: 600;
}

.allchecked h4 {
  text-align: center;
  font-family: Brandon Grotesque;
  font-size: 29.27px;
  font-weight: 600;
  color: #f2b23e;
}

.align {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.std-continue {
  color: #000;
  width: 130px;
  padding: 10px;
  background-color: #fff;
  border-radius: 10px;
  margin-top: 15px;
  font-weight: bold;
  display: inline-block;
  text-decoration: none;
}

.form-control {
  height: 100%;
}

.css-b62m3t-container {
  height: 100% !important;
}

.css-1s2u09g-control {
  height: 100% !important;
}

.std-continue:hover {
  color: #000;
  text-decoration: none;
}

.continue {
  width: 140px;
  padding: 10px;
  color: #000;
  font-size: 15px;
  font-weight: bold;
  border-radius: 10px;
}

.checked {
  width: 140px;
  padding: 10px;
  color: #000;
  font-size: 15px;
  font-weight: bold;
  border-radius: 10px;
}

.main-scroll {
  height: 280px;
  overflow: visible;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 10px;
}

.main-scroll::-webkit-scrollbar {
  height: 10px;
  width: 10px;
  display: none;
}

.student-details h1 {
  font-family: Brandon Grotesque;
  font-weight: bold;
  font-size: 41.03px;
  color: white;
  margin-bottom: 20px;
}

.row-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
}

.welcome {
  font-size: 50px;
  margin-bottom: 30px;
  color: #fff;
  font-weight: 700;
}

.row-flex p {
  margin-top: 10px;
  width: 35%;
  display: flex;
  justify-content: end;
  font-weight: 600;
}

.row-flex input {
  width: 70%;
  margin-left: 10px;
}

.social {
  width: 35%;
  display: flex;
  justify-content: end !important;
}

.row-flex img {
  display: flex;
  margin-left: auto;
  margin-left: 100px;
}

.check-space {
  display: flex;
  gap: 10px;
}

.checkin-type img {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  width: 214.66px !important;
  height: 185.5px !important;
}

.checkin-type h1 {
  font-weight: bold;
  font-size: 41px;
  color: #fff;
  margin-bottom: 30px;
}

.checkin-type input {
  width: 100%;
  padding: 10px;
  background-color: white;
  text-align: center;
  display: flex;
  justify-content: center;
  border-radius: 10px !important;
  outline: none;
  border: none;
  margin-bottom: 20px;
  font-weight: bold;
}

.checkin-type input::placeholder {
  font-weight: bold;
}

.checkin-type .continue-btn {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.continue-btn button {
  background-color: #fff;
  border-radius: 8px;
  color: black;
  font-size: 14px;
  padding-bottom: 5px;
}

.continue-btn .returning-std {
  width: 160px;
  padding: 10px;
  border-radius: 10px !important;
  color: black;
  font-weight: bold;
}

.continue-btn .first-time {
  width: 160px;
  padding: 10px;
  border-radius: 10px !important;
  color: black;
  font-weight: bold;
  margin-left: 10px !important;
}

.display-1 {
  color: white;
  font-style: normal;
  font-weight: bolder;
  font-size: 76.158px;
  line-height: 109px;
  align-items: center;
}

.btn-lg {
  background-color: white;
  color: black;
  margin-right: 25px;
  font-style: normal;
  font-weight: bolder;
  font-size: 25.265px;
  line-height: 36px;
  height: 10vh;
  width: 20%;
  border: 1px solid white;
  border-radius: 16px;
}

.ft {
  background-color: white;

  font-style: normal;
  font-weight: 500;
  font-size: 25.265px;
  line-height: 36px;
  height: 10vh;
  width: 20%;
  border: 1px solid white;
  border-radius: 20px;
}

.container {
  position: relative;
  margin-left: -8%;
}

.smile {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.smile img {
  margin: 5%;
  margin-left: 40%;
}

.red-color {
  /* color: red; */
  font-weight: bold;
  font-size: 18px;
}

.smile h1 {
  color: white;
  font-weight: bold;
  font-size: 48.945px;
  font-style: normal;
  line-height: 70px;
}

.back-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-btn button {
  background-color: white;
  border-radius: 5px !important;
  font-weight: 700;
  color: black;
  font-size: 27.02px;
  width: 25%;
  margin: 5%;
  padding: 5px;
  padding-bottom: 5px;
  padding-left: 13px;
  padding-right: 13px;
}

.checked-all {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checked-all img {
  margin: 5%;
  margin-left: 40%;
}

.checked-all h1 {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 71.929px;
  line-height: 103px;
}

.checked-all h4 {
  color: #f2b23e;
  font-style: normal;
  font-weight: bold;
  font-size: 29.275px;
  line-height: 42px;
  text-align: center;
}

.page-not-found {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-not-found img {
  margin: 10%;
  margin-left: 30%;
}

.page-not-found h1 {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 71.929px;
  line-height: 103px;
  text-align: center;
}

.checkin-closed {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.checkin-closed img {
  margin: 10%;
}

.checkin-closed h1 {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 71.929px;
  line-height: 103px;
  text-align: center;
}

.alr-checked-in {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.alr-checked-in img {
  margin: 10%;
  margin-left: 3%;
}

.alr-checked-in h1 {
  color: white;
  font-style: normal;
  font-weight: bold;
  font-size: 71.929px;
  line-height: 103px;
  text-align: center;
}

@media screen and (max-width: 1200px) {
  .main-wrapper {
    padding: 20px;
  }
}

@media screen and (max-width: 600px) {
  .std-continue {
    margin-left: 0px;
  }

  .main-wrapper {
    padding: 20px;
  }

  .text-end {
    display: flex;
    justify-content: start;
  }

  .main-div {
    width: 400px !important;
  }

  .allchecked h1 {
    font-size: 25px;
  }

  .allchecked h4 {
    font-size: 20px;
    margin-top: 15px;
  }

  .selected-main-div {
    width: 400px !important;
  }

  .social-img {
    margin-left: 0%;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media screen and (max-width: 400px) {
  .main-div {
    width: 300px !important;
  }

  .selected-main-div {
    width: 300px !important;
  }
}

@media screen and (max-width: 300px) {
  .main-div {
    width: auto !important;
  }

  .selected-main-div {
    width: auto !important;
  }
}

/* Camera Page */
.cam-head {
  line-height: 58.94px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width:768px) {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 1.5rem;
    line-height: 20px;
    gap: 1rem;
  }

  @media screen and (max-width:1024px) {
    padding-left: 0px;
    padding-right: 0px;
    line-height: 30px;
    gap: 1rem;
  }
}


.cam-head img {
  width: 141px;

  @media screen and (max-width:768px) {
    width: 91px;

  }
}

.cam-head h1 {
  color: white;
  font-weight: 600;
  font-size: 35.22px;
  padding-bottom: 37px;

  @media screen and (max-width:768px) {
    font-size: 15.22px;
    font-weight: 600;

  }

  @media screen and (max-width:1024px) {
    font-size: 25px;
    font-weight: 600;
    padding-bottom: 20px;

  }
}

.cam-body {
  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding-top: 70px;
  }
}

.web-cam {
  width: 285px;
  height: 285px;

  @media screen and (max-width: 1024px) {
    width: 225px;
    height: 225px;
  }

  @media screen and (max-width: 768px) {
    width: 180px;
    height: 180px;
  }
}